import React from 'react';

import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

const Current = () => (
  <Layout>
    <Sidebar showTabs="false" />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="duaa"
      >
        <div className="w-100">
          <h4 className="mb-5">Duaa and Supplication to Allah الدعاء</h4>
          <ul>
            <li>
              <a href="https://youtu.be/j04srKRT-Ng" target="blank">
                YouTube Video (Please share)
              </a>
            </li>
          </ul>

          <ul>
            <li>
              <a href="https://myislam.net/static/duaa.pdf">
                Written PDF file to download
              </a>
            </li>
            <li>
              <a href="https://www.amazon.com/dp/B08BWJZV3V" target="blank">
                Kindle Book at Amazon
              </a>
            </li>
            <p />
            <li>
              <h4>References</h4>
              <ul>
                <li>
                  <a href="https://islamqa.info/en/answers/262254/ruling-on-offering-supplication-duaa-in-a-language-other-than-arabic-in-the-prayer-and-otherwise">
                    Ruling on offering supplication (du‘aa’) in a language other
                    than Arabic in the prayer and otherwise{' '}
                  </a>
                </li>
                <li>
                  <a href="https://islamqa.info/en/answers/82609/what-is-the-best-time-to-make-dua-on-friday">
                    What Is the Best Time to Make Du’a on Friday?
                  </a>
                </li>
                <li>
                  <a href="https://islamqa.info/ar/answers/82609/%D8%AA%D8%AD%D8%AF%D9%8A%D8%AF-%D8%B3%D8%A7%D8%B9%D8%A9-%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AC%D8%A7%D8%A8%D8%A9-%D9%8A%D9%88%D9%85-%D8%A7%D9%84%D8%AC%D9%85%D8%B9%D8%A9">
                    تحديد ساعة الاستجابة يوم الجمعة{' '}
                  </a>
                </li>

                <li>
                  <a href="https://binbaz.org.sa/fatwas/23159/%D9%85%D8%AA%D9%89-%D9%8A%D9%82%D8%A7%D9%84-%D8%AF%D8%B9%D8%A7%D8%A1-%D9%85%D9%86-%D8%AA%D8%B9%D8%A7%D8%B1-%D9%85%D9%86-%D8%A7%D9%84%D9%84%D9%8A%D9%84">
                    متى يُقال دعاء: «مَنْ تَعارّ من الليل..»
                  </a>
                </li>
                <li>
                  <a href="https://islamqa.info/en/answers/22438/places-and-times-where-duaa-is-answered">
                    Places and times where du’aa’ is answered{' '}
                  </a>
                </li>
                <li>
                  <a href="https://islamqa.info/ar/answers/22438/%D8%A7%D9%85%D8%A7%D9%83%D9%86-%D9%88%D8%A7%D9%88%D9%82%D8%A7%D8%AA-%D8%A7%D8%AC%D8%A7%D8%A8%D8%A9-%D8%A7%D9%84%D8%AF%D8%B9%D8%A7%D8%A1">
                    أماكن وأوقات إجابة الدعاء{' '}
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/btviYS_NypE" target="blank">
                    YouTube Video (Please share)
                  </a>
                </li>
                <li>
                  <a href="https://iloveallah.net/static/Suplication.pdf">
                    Written PDF file to download
                  </a>
                </li>
                <li>
                  <a href="https://myislam.net/static/step1.pdf" target="blank">
                    Slides in PDF format to downlad or read on browser
                  </a>
                </li>
                <li>
                  <a
                    href="https://myislam.net/static/step1.pptx"
                    target="blank"
                  >
                    Slides in PowerPoint format with voice to downlad
                  </a>
                </li>
                <li>
                  <a href="https://myislam.net/static/step1.mp4" target="blank">
                    Video in .mp4 format to downlad or watch on browser
                  </a>
                </li>
                <li>
                  <a
                    href="https://myislam.net/static/jul11plan.pdf"
                    target="blank"
                  >
                    Suggested and example action plan for the week
                  </a>
                </li>
                <li>
                  <a
                    className={Classes.FocusLinked}
                    href="https://www.youtube.com/watch?v=Cqgp878cH5M&t=519s"
                    target="blank"
                  >
                    YouTube Video: Supplication - Introduction
                  </a>
                </li>
                <li>
                  <a
                    className={Classes.FocusLinked}
                    href="https://iloveallah.net/static/Supplication.mp4"
                    target="blank"
                  >
                    Download Video or watch on Browser (.mp4): Supplication -
                    Introduction
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                className={Classes.FocusLinked}
                href="https://www.youtube.com/watch?v=nX4cDaMy7Bw&t=5512s"
                target="blank"
              >
                Stage 2 - 1 Duaa and supplication in Stage 2 Rely on Allah
                Practicing Madrej Assalekeen (YouTube Video)
              </a>
            </li>
            <li>
              <a
                className={Classes.FocusLinked}
                href="https://www.youtube.com/watch?v=osCvLMoNmbA&t=1466s"
                target="blank"
              >
                Stage2-1-1 Duaa -How to get all your supplications accepted? -
                Practicing Madarij
              </a>
            </li>
            <li>
              <a
                className={Classes.FocusLinked}
                href="https://www.facebook.com/watch/?v=1396286917223943"
                target="blank"
              >
                Stage 2 - 1 Duaa and supplication in Stage 2 Rely on Allah
                Practicing Madrej Assalekeen (Facebook Video)
              </a>
            </li>
            <li>
              <a
                className={Classes.FocusLinked}
                href="https://www.facebook.com/watch/?v=2673096459623824"
                target="blank"
              >
                How to get all your supplications accepted? الدعاء المستجاب
                (Facebook Video)
              </a>
            </li>
            <li>
              <a
                className={Classes.FocusLinked}
                href="https://www.islamweb.net/ar/library/index.php?page=bookcontents&idfrom=1571&idto=1572&bk_no=52&ID=544"
              >
                باب ما يتخير من الدعاء بعد التشهد وليس بواجب
              </a>
            </li>

            <li>
              <a
                className={Classes.FocusLinked}
                href="https://www.facebook.com/watch/?v=206212910568028"
              >
                Duaa and supplication - الدعاء (Facebook Video)
              </a>
            </li>
            <li>
              <a href="https://islamqa.info/ar/answers/262254/حكم-الدعاء-بغير-العربية-في-الصلاة-وخارجها">
                حكم الدعاء بغير العربية في الصلاة وخارجها{' '}
              </a>
            </li>
            <li>
              <a
                className={Classes.FocusLinked}
                href="https://www.alukah.net/sharia/0/99720/%D8%A7%D9%84%D8%AF%D8%B9%D8%A7%D8%A1-%D8%A8%D8%B9%D8%AF-%D8%A7%D9%84%D8%AA%D8%B4%D9%87%D8%AF-%D8%A7%D9%84%D8%A3%D8%AE%D9%8A%D8%B1-%D9%81%D9%8A-%D8%A7%D9%84%D8%B5%D9%84%D8%A7%D8%A9/"
              >
                الدعاء بعد التشهد الأخير في الصلاة
              </a>
            </li>
            <p>
              Life is driven by 2 things:
              <ul>
                <li>a. Wishes we wish to get</li>
                <li> b.Problems we wish to solve</li>
              </ul>
              Who does not have wishes in his life? Who does not have problems?
              The imagination of people led them to something that will fulfill
              their wishes. However, even their imaginations were limited. What
              they did not know that we have a better from their fake genie. We
              have the unlimited wishes. Allah SWT said in the closest
              translation, “وَقَالَ رَبُّكُمُ ادْعُونِي أَسْتَجِبْ لَكُمَْ” وقد
              بشّر سبحانه وتعالى عباده بسعة فضله، وعظيم جوده وكرمه، باستجابة
              لدعائهم، وسماعه لطللبهم، فقال: وَقَالَ رَبُّكُمُ ادْعُونِي
              أَسْتَجِبْ لَكُمْ إِنَّ الَّذِينَ يَسْتَكْبِرُونَ عَنْ عِبَادَتِي
              سَيَدْخُلُونَ جَهَنَّمَ دَاخِرِينَ (60) غافر. “And your Lord says:
              "Call on Me; I will answer your (Prayer)" Who is promising you?
            </p>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default Current;
